import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
declare var $: any;

@Component({
  selector: 'app-component-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {
  public url = "";
  submenuPortafolio = false;
  constructor(private router: Router) { }

  ngOnInit(): void {
    this.url = this.router.url;
    
    $(document).ready(function () {
      $(window).scroll(function () {
        
        var scroll = $(window).scrollTop();
        if(scroll >= 60){
          $("#titulo").css("color", "black");
          $("#slogan").css("color", "black");
          $("#logoscroll").css("display", "block");
        }else{
          $("#titulo").css("color", "white");
          $("#slogan").css("color", "white");
          $("#logoscroll").css("display", "none");
        }

      });
    });

    if (this.url == '/servicios') {
      setTimeout(() => {
        $("#nav_servicios").addClass("js_nav-item nav-item active");
      }, 500);
    }
    if (this.url == '/clientes') {
      setTimeout(() => {
        $("#nav_clientes").addClass("js_nav-item nav-item active");
      }, 500);
    }
    if (this.url == '/contactos') {
      setTimeout(() => {
        $("#nav_contactos").addClass("js_nav-item nav-item active");
      }, 500);
    }
  }

  navegar(pagina) {
    if (pagina == 1) {
      this.router.navigate(['/']);
    }if (pagina == 2) {
      this.router.navigate(['/']);
    }
    if (pagina == 3) {
      this.router.navigate(['/servicios']);
    }
    if (pagina == 6) {
      this.router.navigate(['/contactos']);
    }
    if (pagina == 4) {
      this.router.navigate(['/portafolio']);
    }
    if (pagina == 5) {
      this.router.navigate(['/clientes']);
    }

  }

  submenu(submenu) {
    if (submenu == 4) {
      if (this.submenuPortafolio) {
        this.submenuPortafolio = false;
      } else {
        this.submenuPortafolio = true;
      }

    }
  }
}
