import { Component, OnInit, ElementRef } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-clientes',
  templateUrl: './clientes.component.html',
  styleUrls: ['./clientes.component.css'],
})
export class ClientesComponent implements OnInit {
  constructor(private el: ElementRef) {}

  ngOnInit(): void {
    $(document).ready(function () {
      setTimeout(() => {
        $('#ava').on('click', function (e) {
          e.preventDefault();
          var codigo = '#' + $(this).data('ancla');
          $('html,body').animate({ scrollTop: $(codigo).offset().top }, 1);
        });

        $('#ava').trigger('click');
      }, 300);
    });
    const carousels = this.el.nativeElement.querySelectorAll('.carousel');

    carousels.forEach((carousel: HTMLElement) => {
      const inner = carousel.querySelector('.carousel-inner');
      const items = inner?.querySelectorAll('.item');
      const itemWidth = items?.[0].clientWidth || 0;

      const rightControl = carousel.querySelector('.carousel-control.right');
      const leftControl = carousel.querySelector('.carousel-control.left');

      rightControl?.addEventListener('click', () => {
        inner?.scrollBy({ left: itemWidth * 4, behavior: 'smooth' });
      });

      leftControl?.addEventListener('click', () => {
        inner?.scrollBy({ left: -(itemWidth * 4), behavior: 'smooth' });
      });
    });
  }
}
