<div id="_ava" style="margin: 0px !important; padding: 0px !important"></div>
<app-component-menu></app-component-menu>
<img
  class="img-responsive"
  src="assets/img/clientes.png"
  style="height: 400px; width: 100%"
/>

<div class="content-lg container">
  <div class="row">
    <h2 class="heading mb-4 text-center" style="color: #515769 !important">
      Nuestros Clientes
    </h2>
  </div>
  <!--Aqui van los carruseles-->
  <div
    id="carousel1"
    class="carousel slide container"
    data-ride="carousel"
    data-interval="4000"
  >
    <!-- Indicadores -->
    <ol class="carousel-indicators">
      <li data-target="#carousel1" data-slide-to="0" class="active"></li>
      <li data-target="#carousel1" data-slide-to="1"></li>
      <li data-target="#carousel1" data-slide-to="2"></li>
      <li data-target="#carousel1" data-slide-to="3"></li>
      <!-- Añade más indicadores si tienes más grupos de imágenes -->
    </ol>

    <!-- Contenedor de las diapositivas -->
    <div class="carousel-inner">
      <div class="item active">
        <div class="row">
          <div class="col-sm-3">
            <img src="assets/img/clients/prot.png" alt="Imagen 9" />
          </div>
          <div class="col-sm-3">
            <img src="assets/img/clients/play.png" alt="Imagen 10" />
          </div>
          <div class="col-sm-3">
            <img src="assets/img/clients/rep.png" alt="Imagen 11" />
          </div>
          <div class="col-sm-3">
            <img src="assets/img/clients/abracol.png" alt="Imagen 12" />
          </div>
        </div>
      </div>
      <div class="item">
        <div class="row">
          <div class="col-sm-3">
            <img src="assets/img/clients/embassy.png" alt="Imagen 9" />
          </div>
          <div class="col-sm-3">
            <img src="assets/img/clients/eel.png" alt="Imagen 10" />
          </div>
          <div class="col-sm-3">
            <img src="assets/img/clients/capaemc.png" alt="Imagen 11" />
          </div>
          <div class="col-sm-3">
            <img src="assets/img/clients/dino.png" alt="Imagen 12" />
          </div>
        </div>
      </div>
      <div class="item">
        <div class="row">
          <div class="col-sm-3">
            <img src="assets/img/clients/foods.png" alt="Imagen 9" />
          </div>
          <div class="col-sm-3">
            <img src="assets/img/clients/fcpc.png" alt="Imagen 10" />
          </div>
          <div class="col-sm-3">
            <img src="assets/img/clients/ani.png" alt="Imagen 11" />
          </div>
          <div class="col-sm-3">
            <img src="assets/img/clients/copa.png" alt="Imagen 12" />
          </div>
        </div>
      </div>
      <div class="item">
        <div class="row">
          <div class="col-sm-3">
            <img src="assets/img/clients/glamour.png" alt="Imagen 9" />
          </div>
          <div class="col-sm-3">
            <img
              src="assets/img/clients/konica-digital-photo-express.png"
              alt="Imagen 10"
            />
          </div>
          <div class="col-sm-3">
            <img src="assets/img/clients/transconcem.png" alt="Imagen 11" />
          </div>
          <div class="col-sm-3">
            <img src="assets/img/clients/agroden.png" alt="Imagen 12" />
          </div>
        </div>
      </div>
      <!-- Añade más "item" si tienes más grupos de imágenes -->
    </div>

    <!-- Controles -->
    <a class="left carousel-control" href="#carousel1" data-slide="prev">
      <span class="glyphicon glyphicon-chevron-left"></span>
    </a>
    <a class="right carousel-control" href="#carousel1" data-slide="next">
      <span class="glyphicon glyphicon-chevron-right"></span>
    </a>
  </div>
  <br /><br />
  <!-- Repite la estructura para el segundo carrusel -->
  <div
    id="carousel2"
    class="carousel slide container"
    data-ride="carousel"
    data-interval="4000"
  >
    <!-- Indicadores -->
    <ol class="carousel-indicators">
      <li data-target="#carousel2" data-slide-to="0" class="active"></li>
      <li data-target="#carousel2" data-slide-to="1"></li>
      <li data-target="#carousel2" data-slide-to="2"></li>
      <li data-target="#carousel2" data-slide-to="3"></li>
      <!-- Añade más indicadores si tienes más grupos de imágenes -->
    </ol>

    <!-- Contenedor de las diapositivas -->
    <div class="carousel-inner">
      <div class="item active">
        <div class="row">
          <div class="col-sm-3">
            <img src="assets/img/clients/carlota.png" alt="Imagen 9" />
          </div>
          <div class="col-sm-3">
            <img
              src="assets/img/clients/LogoTransparencia_Editado_Jubilacion.jpg"
              alt="Imagen 10"
            />
          </div>
          <div class="col-sm-3">
            <img
              src="assets/img/clients/Caja-de-cesantia-DGAC.png"
              alt="Imagen 11"
            />
          </div>
          <div class="col-sm-3">
            <img
              src="assets/img/clients/LOGO-FONDO-DE-CESANTIA-UNACH.png"
              alt="Imagen 12"
            />
          </div>
        </div>
      </div>
      <div class="item">
        <div class="row">
          <div class="col-sm-3">
            <img
              src="assets/img/clients/fondo_cesantia_espoch.jpg"
              alt="Imagen 9"
            />
          </div>
          <div class="col-sm-3">
            <img src="assets/img/clients/ESPOL-FCPC.png" alt="Imagen 10" />
          </div>
          <div class="col-sm-3">
            <img src="assets/img/clients/FCPC UTA.png" alt="Imagen 11" />
          </div>
          <div class="col-sm-3">
            <img src="assets/img/clients/FOJUPEC.png" alt="Imagen 12" />
          </div>
        </div>
      </div>
      <div class="item">
        <div class="row">
          <div class="col-sm-3">
            <img
              src="assets/img/clients/FCPC GOBERNACION DE BOLIVAR.png"
              alt="Imagen 9"
            />
          </div>
          <div class="col-sm-3">
            <img
              src="assets/img/clients/clinica_integral.jpeg"
              alt="Imagen 10"
            />
          </div>
          <div class="col-sm-3">
            <img src="assets/img/clients/megaaves.png" alt="Imagen 11" />
          </div>
          <div class="col-sm-3">
            <img src="assets/img/clients/telalca.png" alt="Imagen 12" />
          </div>
        </div>
      </div>
      <div class="item">
        <div class="row">
          <div class="col-sm-3">
            <img
              src="assets/img/clients/fpc.jpg"
              alt="Imagen 10"
            />
          </div>
          <div class="col-sm-3">
            <img src="assets/img/clients/maxi.jpg" alt="Imagen 11" />
          </div>
          <div class="col-sm-3">
            <img src="assets/img/clients/jcssb.jpg" alt="Imagen 12" />
          </div>
        </div>
      </div>
      <!-- Añade más "item" si tienes más grupos de imágenes -->
    </div>

    <!-- Controles -->
    <a class="left carousel-control" href="#carousel2" data-slide="prev">
      <span class="glyphicon glyphicon-chevron-left"></span>
    </a>
    <a class="right carousel-control" href="#carousel2" data-slide="next">
      <span class="glyphicon glyphicon-chevron-right"></span>
    </a>
  </div>
  <!--Fin de  los carruseles-->
  <!--IMAGENES ANTIGUAS
  <div class="row">
    <div class="col-sm-3 sm-margin-b-50">
      <img src="assets/img/clients/prot.png" style="width: 100%; height: 150px; margin-left: auto;   margin-right: auto;">
    </div>
    <div class="col-sm-3 sm-margin-b-50">
      <img src="assets/img/clients/play.png" style="width: 100%; height: 150px; margin-left: auto;   margin-right: auto;">
    </div>
    <div class="col-sm-3 sm-margin-b-50">
      <img src="assets/img/clients/rep.png" style="width: 100%; height: 150px; margin-left: auto;   margin-right: auto;">
    </div>
    <div class="col-sm-3 sm-margin-b-50">
      <img src="assets/img/clients/abracol.png" style="width: 100%; height: 150px; margin-left: auto;   margin-right: auto;">
    </div>
  </div>
  <br><br>
  <div class="row">
    <div class="col-sm-3 sm-margin-b-50">
      <img src="assets/img/clients/embassy.png" style="width: 100%; height: 150px; margin-left: auto;   margin-right: auto;">
    </div>
    <div class="col-sm-3 sm-margin-b-50">
      <img src="assets/img/clients/eel.png" style="width: 100%; height: 150px; margin-left: auto;   margin-right: auto;">
    </div>
    <div class="col-sm-3 sm-margin-b-50">
      <img src="assets/img/clients/capaemc.png" style="width: 100%; height: 150px; margin-left: auto;   margin-right: auto;">
    </div>
    <div class="col-sm-3 sm-margin-b-50">
      <img src="assets/img/clients/dino.png" style="width: 100%; height: 150px; margin-left: auto;   margin-right: auto;">
    </div>
  </div>
  <br><br>
  <div class="row">
    <div class="col-sm-3 sm-margin-b-50">
      <img src="assets/img/clients/foods.png" style="width: 100%; height: 150px; margin-left: auto;   margin-right: auto;">
    </div>
    <div class="col-sm-3 sm-margin-b-50">
      <img src="assets/img/clients/fcpc.png" style="width: 100%; height: 150px; margin-left: auto;   margin-right: auto;">
    </div>
    <div class="col-sm-3 sm-margin-b-50">
      <img src="assets/img/clients/ani.png" style="width: 100%; height: 150px; margin-left: auto;   margin-right: auto;">
    </div>
    <div class="col-sm-3 sm-margin-b-50">
      <img src="assets/img/clients/copa.png" style="width: 100%; height: 150px; margin-left: auto;   margin-right: auto;">
    </div>
  </div>
  <br><br>
  <div class="row">
    <div class="col-sm-3 sm-margin-b-50">
      <img src="assets/img/clients/glamour.png" style="width: 100%; height: 150px; margin-left: auto;   margin-right: auto;">
    </div>
    <div class="col-sm-3 sm-margin-b-50">
      <img src="assets/img/clients/konica-digital-photo-express.png" style="width: 100%; height: 150px; margin-left: auto;   margin-right: auto;">
    </div>
     <div class="col-sm-3 sm-margin-b-50">
      <img src="assets/img/clients/transconcem.png" style="width: 100%; height: 150px; margin-left: auto;   margin-right: auto;">
    </div>
    <div class="col-sm-3 sm-margin-b-50">
      <img src="assets/img/clients/agroden.png" style="width: 100%; height: 150px; margin-left: auto;   margin-right: auto;">
    </div>
  </div>
  <br><br>
  <div class="row">
    <div class="col-sm-3 sm-margin-b-50">
      <img src="assets/img/clients/carlota.png" style="width: 100%; height: 150px; margin-left: auto;   margin-right: auto;">
    </div>
    <div class="col-sm-3 sm-margin-b-50">
      <img src="assets/img/clients/LogoTransparencia_Editado_Jubilacion.jpg" style="width: 100%; height: 150px; margin-left: auto;   margin-right: auto;">
    </div>
    <div class="col-sm-3 sm-margin-b-50">
      <img src="assets/img/clients/Caja-de-cesantia-DGAC.png" style="width: 100%; height: 150px; margin-left: auto;   margin-right: auto;">
    </div>
    <div class="col-sm-3 sm-margin-b-50">
      <img src="assets/img/clients/LOGO-FONDO-DE-CESANTIA-UNACH.png" style="width: 100%; height: 150px; margin-left: auto;   margin-right: auto;">
    </div>
  </div>
  <br><br>
  <div class="row">
    <div class="col-sm-3 sm-margin-b-50">
      <img src="assets/img/clients/fondo_cesantia_espoch.jpg" style="width: 100%; height: 150px; margin-left: auto;   margin-right: auto;">
    </div>
    <div class="col-sm-3 sm-margin-b-50">
      <img src="assets/img/clients/ESPOL-FCPC.png" style="width: 100%; height: 150px; margin-left: auto;   margin-right: auto;">
    </div>
    <div class="col-sm-3 sm-margin-b-50">
      <img src="assets/img/clients/FCPC UTA.png" style="width: 100%; height: 150px; margin-left: auto;   margin-right: auto;">
    </div>
    <div class="col-sm-3 sm-margin-b-50">
      <img src="assets/img/clients/FOJUPEC.png" style="width: 100%; height: 150px; margin-left: auto;   margin-right: auto;">
    </div>
  </div>
  <br><br>
  <div class="row">
    <div class="col-sm-3 sm-margin-b-50">
      <img src="assets/img/clients/FCPC GOBERNACION DE BOLIVAR.png" style="width: 100%; height: 150px; margin-left: auto;   margin-right: auto;">
    </div>
    <div class="col-sm-3 sm-margin-b-50">
      <img src="assets/img/clients/clinica_integral.jpeg" style="width: 100%; height: 150px; margin-left: auto;   margin-right: auto;">
    </div>
    <div class="col-sm-3 sm-margin-b-50">
      <img src="assets/img/clients/megaaves.png" style="width: 100%; height: 150px; margin-left: auto;   margin-right: auto;">
    </div>
    <div class="col-sm-3 sm-margin-b-50">
      <img src="assets/img/clients/telalca.png" style="width: 100%; height: 150px; margin-left: auto;   margin-right: auto;">
    </div>
  </div>-->
</div>

<app-component-footer></app-component-footer>

<a href="#" id="ava" class="ancla" data-ancla="_ava" style="display: none"></a>
