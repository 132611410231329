import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api/api.service';
import Swal from 'sweetalert2'
import { NgxSpinnerService  } from "ngx-spinner";

declare var $: any;

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.css']
})
export class ContactsComponent implements OnInit {

  fmrContactos = {
    tema: '-1',
    nombre: '',
    email: '',
    telefono: '',
    mensaje: ''
  }

  constructor(public conexion: ApiService, private spinner:NgxSpinnerService ) { }

  ngOnInit(): void {
    $(document).ready(function () {
      setTimeout(() => {
        $("#ava").on("click", function (e) {
          e.preventDefault();
          var codigo = "#" + $(this).data("ancla");
          $("html,body").animate({ scrollTop: $(codigo).offset().top }, 1);
        });

        $("#ava").trigger("click");
      }, 300);

   
   
    });

    setTimeout(() => {
      this.fmrContactos.tema = '-1';
    }, 1000);
  }

  enviar() {
    $(".loader").addClass("active");

    this.conexion.post("email", "", this.fmrContactos).subscribe(
      (res: any) => { 
        $(".loader").removeClass("active");

       

        console.log(res);

        Swal.fire({
          title: 'Contactos',
          text: 'Mensaje enviado exitosamente',
          icon : 'success'
        });

      },
      err => {
        console.log(err);
        $(".loader").removeClass("active");
      }
    );
  }


}
