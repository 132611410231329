

<div id="_ava" style="margin: 0px !important; padding: 0px !important;"></div>

<app-component-menu></app-component-menu>
<img class="img-responsive" src="assets/img/contactos.jpg" style="height: 300px; width: 100%;">

<div id="form_vas" style="background-color: #FAFAFA;">

  <div class="content">


    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-10">


          <div class="row justify-content-center">
            <div class="col-md-6">

              <h3 class="heading mb-4" style="color: #515769 !important;">Contáctanos</h3>
              <p style="color: #515769 !important;">No dudes en realizar tu consulta</p>

              <p><img src="assets/img/undraw-contact.svg" alt="Image" class="img-fluid" style="width: 100% !important;">
              </p>


            </div>
            <div class="col-md-6">

              <form class="mb-5">
                <div class="row">
                  <div class="col-md-12 form-group">
                    <input type="text" class="form-control" name="nombre" id="nombre" placeholder="Nombre"
                      [(ngModel)]="fmrContactos.nombre">
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12 form-group">
                    <input type="text" class="form-control" name="email" id="email" placeholder="Correo Elecrónico"
                      [(ngModel)]="fmrContactos.email">
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12 form-group">
                    <input type="text" class="form-control" name="telefono" id="telefono" placeholder="Teléfono"
                      [(ngModel)]="fmrContactos.telefono">
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12 form-group">
                    <select class="form-control" name="tema" [(ngModel)]="fmrContactos.tema">
                      <option [value]="-1" disabled selected>Tema de Consulta</option>
                      <option [value]="'CONSULTORÍA EN EL ÁREA TRIBUTARIA'">CONSULTORÍA EN EL ÁREA TRIBUTARIA</option>
                      <option [value]="'CONSULTORÍA FINANCIERA'">CONSULTORÍA FINANCIERA</option>
                      <option [value]="'AUDITORÍA EXTERNA FINANCIERA'">AUDITORÍA EXTERNA FINANCIERA</option>
                      <option [value]="'CONSULTORÍA Y AUDITORÍA SOCIETARÍA'">CONSULTORÍA Y AUDITORÍA SOCIETARÍA</option>
                      <option [value]="'ASESORÍA FINANCIERA'">ASESORÍA FINANCIERA </option>
                      <option [value]="'ASESORÍA LABORAL'">ASESORÍA LABORAL</option>
                      <option [value]="'OUTSOURCING CONTABLE'">OUTSOURCING CONTABLE </option>
                      <option [value]="'REVISIONES FINANCIERAS'">REVISIONES FINANCIERAS </option>
                      <option [value]="'OTROS'">OTROS</option>
                    </select>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12 form-group">
                    <textarea class="form-control" cols="30" rows="20" style="height: 100px;" name="mensaje"
                      placeholder="Mensaje" [(ngModel)]="fmrContactos.mensaje"></textarea>
                  </div>
                </div>
                <div class="row" style="padding-left: 15px; padding-right: 15px;">
                  <br>
                  <div class="col-12">
                    <div class="loader">
                      <img src="assets/img/spinner.gif" style="width: 100%; height: 190px; margin-left: auto;   margin-right: auto;">
                     </div>
                    <input type="submit" value="Enviar" (click)="enviar()" class="btn-contactanos rounded-0 py-2 px-4">
                    <span class="submitting"></span>
                  </div>
                </div>
              </form>

              <div id="form-message-warning mt-4"></div>

            </div>
          </div>
        </div>
      </div>
    </div>
</div>
</div>

<app-component-footer></app-component-footer>

<a href="#" id="ava" class="ancla" data-ancla="_ava" style="display: none;"></a>