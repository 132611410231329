import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MenuComponent } from './view/components/menu/menu.component';
import { FooterComponent } from './view/components/footer/footer.component';
import { FloatComponent } from './view/components/float/float.component';
import { IndexComponent } from './view/index/index.component';
import { ContactsComponent } from './view/contacts/contacts.component';
import { ServicesComponent } from './view/services/services.component';
import { PortafolioComponent } from './view/portafolio/portafolio.component';
import { ClientesComponent } from './view/clientes/clientes.component';
import { NgxSpinnerModule } from "ngx-spinner";

@NgModule({
  declarations: [
    AppComponent,
    MenuComponent,
    FooterComponent,
    FloatComponent,
    IndexComponent,
    ContactsComponent,
    ServicesComponent,
    PortafolioComponent,
    ClientesComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    NgxSpinnerModule,

  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
