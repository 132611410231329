import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
declare var $: any;

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.css']
})
export class IndexComponent implements OnInit {


  constructor(private router: Router) { }

  ngOnInit(): void {
   
  }

  navegar(pagina){
    if(pagina == 6){
      this.router.navigate(['/contactos']);
    }
   
  }



}
