<header class="header navbar-fixed-top">
  <nav class="navbar" role="navigation" style="border-radius: 0px !important;">
    <div class="container">
      <div class="menu-container js_nav-item">
        <button type="button" class="navbar-toggle" data-toggle="collapse" data-target=".nav-collapse">
          <span class="sr-only">Toggle navigation</span>
          <span class="toggle-icon"></span>
        </button>

        <div class="logo menu-width-web">
          <a class="logo-wrap" href="#body">

            <table style="width: 100%;" class="menu-web">
              <tr>
                <td> 
                  <img class="logo-img logo-img-main" src="assets/img/logos/logosolo.png"
                    style="width: 75px !important;">

                    <img src="assets/img/logos/logosolo.png" id="logoscroll" style="display: none;" width="65">
                  </td>
                <td
                  style="padding: 0px !important; margin: 0px !important; line-height: 16px !important; color: #fff; text-align: center;">
                  <b id="titulo">VÁSQUEZ & ASOCIADOS CÍA. LTDA.</b> <br>
                  <small id="slogan">AUDITORÍA Y CONSULTORÍA</small>
                </td>
              </tr>
            </table>

            <img class="logo-img logo-img-active image-movil" src="assets/img/logos/logoh - Copy.png"
              style="width: 75% !important;">

          </a>
        </div>
      </div>

      <div class="collapse navbar-collapse nav-collapse">
        <div class="menu-container">
          <ul class="nav navbar-nav navbar-nav-right">
            <li class="js_nav-item nav-item"><a class="nav-item-child nav-item-hover" href="#body" (click)="navegar(1)">Inicio</a>
            </li>
            <li class="js_nav-item nav-item"><a class="nav-item-child nav-item-hover" href="#about" (click)="navegar(2)">Nosotros</a>
            </li>
            <li id="nav_servicios"  class="js_nav-item nav-item">
              <a class="nav-item-child nav-item-hover" style="cursor: pointer;" (click)="navegar(3)">Servicios</a>
            </li>
            <!-- <li class="js_nav-item nav-item">
              <a class="nav-item-child nav-item-hover" style="cursor: pointer;" (mouseenter)="submenu(4) "
                >
                Portafolio
              </a>

              <ul *ngIf="submenuPortafolio" style="display: inline-block; list-style: none; padding-top: 0px !important; margin-top: 0px !important; top: 0px !important;">
                <li>
                  <a href="" style="color: #fff; text-transform: uppercase;">Boletines</a>
                </li>
                <li>
                  <a href="" style="color: #fff; text-transform: uppercase;">Enlaces de Interes</a>
                </li>
              </ul> 

            </li>-->
            <li id="nav_clientes" class="js_nav-item nav-item"><a class="nav-item-child nav-item-hover" (click)="navegar(5)">Clientes</a>
            </li>
            <li id="nav_contactos" class="js_nav-item nav-item"><a class="nav-item-child nav-item-hover" style="cursor: pointer;"
                (click)="navegar(6)">Contactos</a></li>
          </ul>

        </div>
      </div>
    </div>
  </nav>
</header>




