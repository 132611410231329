import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ClientesComponent } from './view/clientes/clientes.component';
import { ContactsComponent } from './view/contacts/contacts.component';
import { IndexComponent } from './view/index/index.component';
import { PortafolioComponent } from './view/portafolio/portafolio.component';
import { ServicesComponent } from './view/services/services.component';

const routes: Routes = [
  { path: '', component: IndexComponent },
  { path: 'contactos', component: ContactsComponent },
  { path: 'servicios', component: ServicesComponent },
  { path: 'portafolio', component: PortafolioComponent },
  { path: 'clientes', component: ClientesComponent },


  

  
  
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{ useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
