
<div id="detalleWp" class="flotante-detalle">
  <div class="wp-head">
    <img src="../../../../../assets/img/icons/wp.png" alt="" width="23"> &nbsp; Inicia una conversación
  </div>
  <div class="wp-body">
    <div style="padding-bottom: 5px;">
      <small style="font-size: 15px;">¿Necesita más Información?   Estaremos gustosos de ayudarle.
        </small>
    </div>
    <a href="https://wa.me/593998280808?text=Saludos, necesito conversar con uno de sus expertos financieros."
      target="_blank">
      <div class="wp-card">
        <div class="row">
          <div class="col-2">
            <img src="../../../../../assets/img/icons/wp-card-l.png" width="35"> &nbsp;&nbsp; Expertos Financieros
          </div>
        </div>
      </div>
    </a>
    <br>
    <a href="https://wa.me/593969029944?text=Saludos, necesito información de sus servicios de auditoría, consultoría y contabilidad."
      target="_blank">
      <div class="wp-card">
        <div class="row">
          <div class="col-2">
            <img src="../../../../../assets/img/icons/wp-card-l.png" width="35"> &nbsp;&nbsp; Gerencia
          </div>
        </div>
      </div>
    </a>
    <br>
  </div>
</div>
<div id="btnWp" class='flotante'>
  <img src='../../../../../assets/img/icons/whatsapp.webp' width="45" />
</div>
<div id="btnWpClose" class='flotante'>
  <img src='../../../../../assets/img/icons/wp-close.png' width="45" />
</div>



<div id="container-floating">

  <div class="nd4 nds" data-toggle="tooltip" data-placement="left" data-original-title="TikTok">
    <a href="https://www.tiktok.com/@vasquezyasociadoscialtda?_d=secCgYIASAHKAESMgow5jdk%2BDeSRs6sVVrfTgVI%2FK%2BvfkB4Icyu%2FYjJRnenE04soux5hkbcPR64W9vbNj%2BhGgA%3D&language=es&sec_uid=MS4wLjABAAAAoK1KxQ6rj0MaqudgdtQfu_0ctpi5vwdwrrcTdkwzbMdeGunCxM8h2OKTYD9xVgr6&sec_user_id=MS4wLjABAAAAoK1KxQ6rj0MaqudgdtQfu_0ctpi5vwdwrrcTdkwzbMdeGunCxM8h2OKTYD9xVgr6&share_app_id=1233&share_author_id=6974574051763717125&share_link_id=02a357f2-4388-4b67-9d01-e3715cf16e1d&timestamp=1625857545&u_code=dj603fd9i084j6&user_id=6974574051763717125&utm_campaign=client_share&utm_medium=android&utm_source=whatsapp&source=h5_m&_r=1"
      target="_blank">
      <img class="reminder" src="assets/img/icons/tik-tok.png" width="20" />
    </a>
  </div>
  <div class="nd3 nds" data-toggle="tooltip" data-placement="left" data-original-title="Instagram">
    <a href="https://www.instagram.com/auditoriayconsultoriavasquez/?utm_medium=copy_link" target="_blank">
      <img class="reminder" src="assets/img/icons/instagram.png" width="20" />
    </a>
  </div>

  <div class="nd1 nds" data-toggle="tooltip" data-placement="left" data-original-title="Facebook">
    <a href="https://www.facebook.com/AuditoriayConsultoriaVasquezyAsociados/?ref=pages_you_manage" target="_blank">
      <img class="reminder" src="assets/img/icons/facebook.png" width="20" />
    </a>
  </div>

  <div id="floating-button" data-toggle="tooltip" data-placement="left" data-original-title="Social">
    <p class="plus">
      <img src="assets/img/icons/social.png" width="30">
    </p>
    <img class="edit" src="assets/img/icons/social.png" width="30">
  </div>

</div>