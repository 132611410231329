<div id="_ava" style="margin: 0px !important; padding: 0px !important;"></div>
<app-component-menu></app-component-menu>
<img class="img-responsive" src="assets/img/servicios.jpg" style="height: 300px; width: 100%;">
<br><br>
<div>
  <div class="container">
    <div class="col-md-4">
      <figure class="snip0015">
        <img src="assets/img/servicios/servicio1.png" />
        <figcaption>

          <p>CONSULTORÍA EN EL ÁREA TRIBUTARIA</p>

        </figcaption>
      </figure>
      <p style="text-align: center;">
        <a style="background-color: transparent; color: rgb(85, 82, 81); color: #00b3ff; font-weight: bold;"
          data-toggle="collapse" href="#servicio4" role="button" aria-expanded="false" aria-controls="collapseExample">
          &nbsp; &nbsp; &nbsp; Más Detalles&nbsp;&nbsp; <i class="fa fa-plus-square" style="color: #f4941c;"></i>
        </a>

      </p>
      <div class="collapse" id="servicio4">
        <div class="card card-body" style=" border: 2px solid #ddd; padding: 20px; border-radius: 10px;">

          <ul>
            <li>Planificación Tributaria Anual.</li>
            <li>Inscripción y Actualización en el RUC.</li>
            <li>Diseño de escenarios posibles y alternativas de ahorro tributario.</li>
            <li>Asesoramiento de beneficios Tributarios.</li>
            <li>Absolución de consultas tributarias.</li>
            <li>Cursos de Entrenamiento y Capacitaciones.</li>
            <li>Seguimiento y Asesoría en el Régimen de Microempresas.</li>
            <li>Elaboración del Mapeo de Impuesto a la Renta </li>
            <li>Cálculos de Reinversión de Utilidades y Cálculos de retención en fuente.</li>
            <li>Impuesto a las Herencias.</li>
            <li>Declaraciones Patrimoniales.</li>
            <li>Devolución de Impuesto al Valor Agregado y otros Impuestos Fiscales</li>
            <li>Análisis de los Convenios para evitar la doble imposición.</li>
            <li>Y otros Servicios Profesionales...</li>
          </ul>


        </div>
      </div>
    </div>
    <div class="col-md-4">
      <figure class="snip0015">
        <img src="assets/img/servicios/servicio2.png" alt="sample38" />
        <figcaption>

          <p>CONSULTORÍA FINANCIERA</p>

        </figcaption>
      </figure>
      <p style="text-align: center;">
        <a style="background-color: transparent; color: rgb(85, 82, 81); color: #00b3ff; font-weight: bold;"
          data-toggle="collapse" href="#servicio5" role="button" aria-expanded="false" aria-controls="collapseExample">
          &nbsp; &nbsp; &nbsp; Más Detalles&nbsp;&nbsp; <i class="fa fa-plus-square" style="color: #f4941c;"></i>
        </a>

      </p>
      <div class="collapse" id="servicio5">
        <div class="card card-body" style=" border: 2px solid #ddd; padding: 20px; border-radius: 10px;">

          <ul>
            <li>Acompañamiento en la implementación de software (contable, financiero, tributario y de
              nómina)</li>
            <li>Revisión y diagnóstico de las áreas de la empresa en la que se pueden obtener ahorros
              considerables de costos u optimización de procesos para generar mejores márgenes de
              contribución.</li>
            <li>Informes de Comisario de Estados Financieros.</li>
            <li>Proyección de Utilidades</li>
            <li>Combinación y Consolidación de los estados financieros de Matrices y Subsidiarias y Grupos
              Empresariales de acuerdo con las NIIF y NIC</li>
            <li>Asesoramiento en procesos de Escisión y Fusión de Compañías.</li>
            <li>Análisis y Reconstrucción de Estados Financieros.</li>
          </ul>


        </div>
      </div>
    </div>
    <div class="col-md-4">
      <figure class="snip0015">
        <img src="assets/img/servicios/servicio3.png" alt="sample38" />
        <figcaption>

          <p>CONSULTORÍA Y AUDITORÍA SOCIETARIA</p>

        </figcaption>
      </figure>
      <p style="text-align: center;">
        <a style="background-color: transparent; color: rgb(85, 82, 81); color: #00b3ff; font-weight: bold;"
          data-toggle="collapse" href="#servicio6" role="button" aria-expanded="false" aria-controls="collapseExample">
          &nbsp; &nbsp; &nbsp; Más Detalles&nbsp;&nbsp; <i class="fa fa-plus-square" style="color: #f4941c;"></i>
        </a>

      </p>
      <div class="collapse" id="servicio6">
        <div class="card card-body" style=" border: 2px solid #ddd; padding: 20px; border-radius: 10px;">

          <ul>
            <li>Apoyo en elaboración de Informes de la Gerencia Anuales a ser presentados a la Junta General
              de Accionista y/o Socios y a la Superintendencia de Compañías,
              Valores y Seguros del Ecuador.</li>
            <li>Confección de libros de acciones y accionistas y/o de participaciones y sus títulos o
              certificados de aportación.</li>
            <li>Asistencia en cesión de acciones y participaciones.</li>
            <li>Diseño de Expediente de Actas de Junta General de Accionistas y Socios.</li>
            <li>Convocatorias a Juntas de Accionistas, desarrollo de Juntas Generales.</li>
            <li>Apoyo en constitución de empresas, reformas de estatutos, incrementos de capital,
              disminuciones de capital, transformaciones, reactivación de compañías y
              liquidación y/o disolución de compañías.</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container">
  <div class="col-md-4">
    <figure class="snip0015">
      <img src="assets/img/servicios/servicio4.png" alt="sample38" />
      <figcaption>

        <p>ASESORÍA LABORAL</p>

      </figcaption>
    </figure>
    <p style="text-align: center;">
      <a style="background-color: transparent; color: rgb(85, 82, 81); color: #00b3ff; font-weight: bold;"
        data-toggle="collapse" href="#servicio7" role="button" aria-expanded="false" aria-controls="collapseExample">
        &nbsp; &nbsp; &nbsp; Más Detalles&nbsp;&nbsp; <i class="fa fa-plus-square" style="color: #f4941c;"></i>
      </a>

    </p>
    <div class="collapse" id="servicio7">
      <div class="card card-body" style="border: 2px solid #ddd; padding: 20px; border-radius: 10px;">

        <ul>
          <li>Absolución de todas las consultas verbales y escritas de carácter laboral.</li>
          <li>Elaboración de contratos de trabajo.</li>
          <li>Registro de los contratos en el Ministerio de Trabajo.</li>
          <li>Elaboración de actas de finiquito.</li>
          <li>Registro de las actas en el Ministerio de Trabajo.</li>
          <li>Registro de formularios de pago de utilidades, décimo tercero y décimo cuarto sueldos.</li>
          <li>Desahucios. </li>
          <li>Reglamentos Internos.</li>
          <li>Negociación colectiva.</li>
          <li>Auditorias de cumplimiento de obligaciones laborales y frente al IESS.</li>
          <li>Negociaciones y mediaciones con el personal en caso de presentarse controversias.</li>
        </ul>
      </div>
    </div>
  </div>

  <div class="col-md-4">
    <figure class="snip0015">
      <img src="assets/img/servicios/servicio5.png" />
      <figcaption>

        <p>AUDITORÍAS EXTERNAS Y REVISIONES FINANCIERAS</p>

      </figcaption>
    </figure>
    <p style="text-align: center;">
      <a style="background-color: transparent; color: rgb(85, 82, 81); color: #00b3ff; font-weight: bold;"
        data-toggle="collapse" href="#servicio8" role="button" aria-expanded="false" aria-controls="collapseExample">
        &nbsp; &nbsp; &nbsp; Más Detalles&nbsp;&nbsp; <i class="fa fa-plus-square" style="color: #f4941c;"></i>
      </a>

    </p>
    <div class="collapse" id="servicio8">
      <div class="card card-body" style="border: 2px solid #ddd; padding: 20px; border-radius: 10px;">

        <ul>
          <li>Auditoría externa de los estados financieros con informe de cumplimiento tributario.</li>
          <li>Auditoría para propósitos de consolidación.</li>
          <li>Auditorías especiales bajo procedimientos convenidos (NIA 4400).</li>
          <li>Revisión de los estados financieros intermedios.</li>
          <li>Revisiones de Rubros o componentes específicos.</li>
          <li>Auditoría Forense.</li>
          <li>Due Dilligence: Procedimientos acordados para compra, venta de empresas.</li>
          <li>Procedimientos acordados para compra-valoración de empresas.</li>
          <li>Evaluación de segregación de funciones.</li>
          <li>Evaluación de eficacia operativa de controles.</li>
          <li>Procedimientos acordados para prevención de lavado de Activos.</li>
        </ul>


      </div>
    </div>
  </div>

  <div class="col-md-4">
    <figure class="snip0015">
      <img src="assets/img/servicios/servicio7.png" alt="sample38" />
      <figcaption>

        <p>OUTSOURCING CONTABLE</p>

      </figcaption>
    </figure>
    <p style="text-align: center;">
      <a style="background-color: transparent; color: rgb(85, 82, 81); color: #00b3ff; font-weight: bold;"
        data-toggle="collapse" href="#servicio10" role="button" aria-expanded="false" aria-controls="collapseExample">
        &nbsp; &nbsp; &nbsp; Más Detalles&nbsp;&nbsp; <i class="fa fa-plus-square" style="color: #f4941c;"></i>
      </a>

    </p>
    <div class="collapse" id="servicio10">
      <div class="card card-body" style="border: 2px solid #ddd; padding: 20px; border-radius: 10px;">

        <ul>
        <li>Servicio de Outsourcing Contable que incluye manejo de Nómina, Activos Fijos, Inventarios, Cuentas por Pagar.</li>	
        <li>EBITDA Improvement y SCT: Revisión y diagnóstico de las áreas de la empresa en la que se pueden obtener ahorros considerables 
          de costos u optimización de procesos para generar mejores márgenes de contribución.</li>
        <li>Informes de Comisario de Estados Financieros.</li>
        <li>Proyección de Utilidades.</li>
        <li>Combinación y Consolidación de los estados financieros de Matrices y Subsidiarias y Grupos Empresariales de acuerdo con las NIIF y NIC.</li>
        <li>Asesoramiento en procesos de Escisión y Fusión de Compañías.</li>
        <li>Análisis y Reconstrucción de Estados Financieros en caso de no haber existido una contabilidad definida, y que incluye planteamiento de ajustes
            para una razonable presentación de los estados financieros.</li>

        </ul>
      </div>
    </div>
  </div>
</div>

<div class="container">
  <div class="col-md-4">
   <!--  <figure class="snip0015">
      <img src="assets/img/servicios/servicio4.png" alt="sample38" />
      <figcaption>

        <p>ASESORÍA LABORAL</p>

      </figcaption>
    </figure>
    <p>
      <a style="background-color: transparent; color: rgb(85, 82, 81); color: #00b3ff; font-weight: bold;"
        data-toggle="collapse" href="#servicio7" role="button" aria-expanded="false" aria-controls="collapseExample">
        &nbsp; &nbsp; &nbsp; Más Detalles&nbsp;&nbsp; <i class="fa fa-plus-square" style="color: #f4941c;"></i>
      </a>

    </p>
    <div class="collapse" id="servicio7">
      <div class="card card-body" style="border: 2px solid #ddd; padding: 20px; border-radius: 10px;">

        <ul>
          <li>Absolución de todas las consultas verbales y escritas de carácter laboral.</li>
          <li>Elaboración de contratos de trabajo.</li>
          <li>Registro de los contratos en el Ministerio de Trabajo.</li>
          <li>Elaboración de actas de finiquito.</li>
          <li>Registro de las actas en el Ministerio de Trabajo.</li>
          <li>Registro de formularios de pago de utilidades, décimo tercero y décimo cuarto sueldos.</li>
          <li>Desahucios. </li>
          <li>Reglamentos Internos.</li>
          <li>Negociación colectiva.</li>
          <li>Auditorias de cumplimiento de obligaciones laborales y frente al IESS.</li>
          <li>Negociaciones y mediaciones con el personal en caso de presentarse controversias.</li>
        </ul>
      </div>
    </div> -->
  </div>

  <div class="col-md-4">
    <figure class="snip0015" style="background-color: #fff; border: 1px solid #000">
      <img src="assets/img/logos/logosolo.png" style="width: 248px;"/>
      <figcaption>

        <p style="color: black;">DESCARGA EL DETALLE DE NUESTROS SERVICIOS</p>

      </figcaption>
    </figure>
    <p style="text-align: center;">
      <a style="background-color: transparent; color: rgb(85, 82, 81); color: #00b3ff; font-weight: bold;"
        href="assets\pdf\ServiciosAuditoriaVasquez.pdf" target="_blank">
        &nbsp; &nbsp; Descargar&nbsp;&nbsp; <i class="fa fa-download" style="color: #f4941c;"></i>
      </a>

    </p>
  </div>

  <div class="col-md-4">
<!--     <figure class="snip0015">
      <img src="assets/img/servicios/servicio5.png" />
      <figcaption>

        <p>AUDITORÍAS EXTERNAS Y REVISIONES FINANCIERAS</p>

      </figcaption>
    </figure>
    <p>
      <a style="background-color: transparent; color: rgb(85, 82, 81); color: #00b3ff; font-weight: bold;"
        data-toggle="collapse" href="#servicio8" role="button" aria-expanded="false" aria-controls="collapseExample">
        &nbsp; &nbsp; &nbsp; Más Detalles&nbsp;&nbsp; <i class="fa fa-plus-square" style="color: #f4941c;"></i>
      </a>

    </p>
    <div class="collapse" id="servicio8">
      <div class="card card-body" style="border: 2px solid #ddd; padding: 20px; border-radius: 10px;">

        <ul>
          <li>Auditoría externa de los estados financieros con informe de cumplimiento tributario.</li>
          <li>Auditoría para propósitos de consolidación.</li>
          <li>Auditorías especiales bajo procedimientos convenidos (NIA 4400).</li>
          <li>Revisión de los estados financieros intermedios.</li>
          <li>Revisiones de Rubros o componentes específicos.</li>
          <li>Auditoría Forense.</li>
          <li>Due Dilligence: Procedimientos acordados para compra, venta de empresas.</li>
          <li>Procedimientos acordados para compra-valoración de empresas.</li>
          <li>Evaluación de segregación de funciones.</li>
          <li>Evaluación de eficacia operativa de controles.</li>
          <li>Procedimientos acordados para prevención de lavado de Activos.</li>
        </ul>


      </div>
    </div> -->
  </div>


</div>
<br><br>

<app-component-footer></app-component-footer>

<a href="#" id="ava" class="ancla" data-ancla="_ava" style="display: none;"></a>