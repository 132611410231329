<app-component-float></app-component-float>

<div class="map height-300">
  <iframe
    src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3989.7989035554433!2d-78.4899328250354!3d-0.1787515998194872!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMMKwMTAnNDMuNSJTIDc4wrAyOScxNC41Ilc!5e0!3m2!1sen!2sec!4v1716087540960!5m2!1sen!2sec"
    width="100%"
    height="300px"
    style="border: 0"
    allowfullscreen=""
    loading="lazy"
  ></iframe>
</div>
<br /><br />
<div class="container" style="margin-bottom: 20px !important">
  <div class="row">
    <div class="col-md-4">
      <i
        class="fa fa-envelope fa-2x"
        style="
          padding-top: 15px;
          color: #f4941c;
          display: flex;
          align-items: center;
          justify-content: center;
        "
      ></i>

      <strong>
        <p style="color: #049ccb; text-align: center; font-size: 18px">
          <b>Correo Elecrónico</b>
        </p>
      </strong>
      <div style="text-align: center">
        <ul style="list-style-type: none">
          <li>
            <b>Gabriel Vásquez:</b> <br />
            <a
              href="mailto:gvasquez@auditoriavasquez.com"
              style="color: #64748c"
            >
              <u>gvasquez@auditoriavasquez.com</u>
            </a>
          </li>
          <li>
            <br />
            <b>Marisela Gómez:</b> <br />
            <a
              href="mailto:mgomez@auditoriavasquez.com"
              style="color: #64748c; font-size: 15px"
            >
              <u>mgomez@auditoriavasquez.com</u>
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div class="col-md-4">
      <i
        class="fa fa-phone fa-2x"
        style="
          padding-top: 15px;
          color: #f4941c;
          display: flex;
          align-items: center;
          justify-content: center;
        "
      ></i>
      <strong>
        <p style="color: #049ccb; text-align: center; font-size: 18px">
          <b>Contactos</b>
        </p>
      </strong>

      <div style="text-align: center">
        <ul style="list-style-type: none">
          <li>
            <b>Expertos Financieros:</b>
            <br />
            <a
              href="https://wa.me/593983750769?text=Saludos, necesito conversar con uno de sus expertos financieros."
              target="_blank"
              style="color: #64748c"
            >
              <u>098 375 0769</u>
            </a>
          </li>
          <li>
            <br />
            <b>Gerencia:</b>
            <br />
            <a
              href="https://wa.me/593969029944?text=Saludos, necesito información de sus servicios de auditoría, consultoría y contabilidad."
              target="_blank"
              style="color: #64748c"
            >
              <u>096 902 9944</u>
            </a>
          </li>
        </ul>
      </div>
    </div>

    <div class="col-md-4">
      <i
        class="fa fa-map-marker fa-2x"
        style="
          padding-top: 15px;
          color: #f4941c;
          display: flex;
          align-items: center;
          justify-content: center;
        "
      ></i>
      <strong>
        <p style="color: #049ccb; text-align: center; font-size: 18px">
          <b>Ubicación</b>
        </p>
      </strong>
      <div style="text-align: center">
        <p style="font-size: 15px; color: #64748c; font-weight: bold">
          Ignacio San María y Nuñez de Vela.
          <br />
          Edificio Metropoli oficina 201
          <br />
          Quito- Ecuador
        </p>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12" style="text-align: center !important">
      <span style="color: rgb(175, 175, 175) !important; font-size: 16px">
        <br />
        Desarrollado por: <b>S&S TEC</b> || &nbsp; Oscar Rivera
        <b>&</b> Francisco Campo &nbsp;|| <b> 099 501 6727 - 098 398 3436</b>
      </span>
    </div>
  </div>
</div>
