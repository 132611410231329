<app-component-menu></app-component-menu>

<div id="carousel-example-generic" class="carousel slide" data-ride="carousel">
  <div class="container">
    <ol class="carousel-indicators">
      <li
        data-target="#carousel-example-generic"
        data-slide-to="0"
        class="active"
      ></li>
      <li data-target="#carousel-example-generic" data-slide-to="1"></li>
      <li data-target="#carousel-example-generic" data-slide-to="2"></li>
    </ol>
  </div>

  <div class="carousel-inner" role="listbox">
    <div class="item active">
      <img
        class="img-responsive"
        src="assets/img/carrusel/carr1.jpg"
        alt="Slider Image"
      />
      <div class="container">
        <div class="carousel-centered">
          <div class="margin-b-40">
            <h1 class="text-light" style="color: #fff">
              AUDITORÍA Y CONSULTORÍA
            </h1>
            <p class="color-white" style="font-size: 18px">
              Nuestro profesionalismo es la presentación de nuestras capacidades
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="item">
      <img
        class="img-responsive"
        src="assets/img/carrusel/carr2.jpg"
        alt="Slider Image"
      />
      <div class="container">
        <div class="carousel-centered">
          <div class="margin-b-40">
            <h1 class="text-light" style="color: #fff">
              AUDITORÍA Y CONSULTORÍA
            </h1>
            <p class="color-white" style="font-size: 18px">
              Los Socios, tus mejores aliados con los que puedes contar
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="item">
      <img
        class="img-responsive"
        src="assets/img/carrusel/carr3.jpg"
        alt="Slider Image"
      />
      <div class="container">
        <div class="carousel-centered">
          <div class="margin-b-40">
            <h1 class="text-light" style="color: #fff">
              AUDITORÍA Y CONSULTORÍA
            </h1>
            <p class="color-white" style="font-size: 18px">
              Auditorías externas y revisiones a empresas del sector Público y
              Privado
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- VIDEO | MISION | VISION | VALORES -->
<div>
  <div class="content-lg container">
    <div class="row">
      <div class="col-md-5">
        <div class="margin-b-10">
          <h2>Presentación</h2>
          <p style="color: #353535; text-align: justify">
            En nuestros quehaceres diarios, actuamos con respeto y honestidad,
            asumiendo con responsabilidad todas las necesidades de nuestros
            clientes y actuando con principios éticos en el ámbito tanto
            personal como profesional.
          </p>
        </div>
      </div>
      <div class="col-md-7">
        <iframe
          width="100%"
          height="300"
          src="https://www.youtube.com/embed/vQaCNJAwUro"
          title="Auditoría Vásquez &  Asociados"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
        <!--<video
          src="https://youtu.be/vQaCNJAwUro"
          width="100%"
          height="300"
          controls
          poster="assets/img/logos/logosolo.png"
        >
          Lo sentimos. Este vídeo no puede ser reproducido en tu navegador.<br />
          La versión descargable está disponible en
          <a href="assets/img/videos/video1.mp4" target="_blank">Enlace</a>.
        </video>-->
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <p style="text-align: center; padding: 5rem; font-size: x-large">
        Member of AUDITAXES International LLP, Global Association of Independent
        Accounting Firms
      </p>
    </div>
    <div
      class="col-md-6"
      style="text-align: center; padding-left: 9rem; padding-right: 9rem"
    >
      <a href="https://auditaxes.com/" target="_blank">
        <div class="contenedor-imagen">
          <img
            src="assets\img\Auditaxes.png"
            alt="Imagen Original"
            class="imagen-original"
          />
          <img
            src="assets\img\visitanos.png"
            alt="Imagen Hover"
            class="imagen-hover"
          />
        </div>
      </a>
    </div>
  </div>
  <div id="about" class="bg-color-sky-light">
    <div class="content-lg container">
      <div class="row">
        <div class="col-md-5 col-sm-5 md-margin-b-60">
          <div class="margin-t-50 margin-b-30">
            <h2>Acerca de Nosotros</h2>
            <p style="color: #353535; text-align: justify">
              Somos una Firma ecuatoriana de asesoramiento empresarial, que
              brinda servicios profesionales especializados en consultoría
              corporativa financiera, tributaria, laboral, societaria, de
              negocios y de auditoria. <br /><br />
              Somos un equipo de profesionales que brindamos servicios de alta
              calidad en Auditoría Externa e Interna, Servicios Contables,
              Servicios Tributarios y de Consultoría.
            </p>
          </div>
          <div class="dropdown">
            <button
              class="btn-theme text-uppercase dropdown-toggle"
              style="
                color: #f3941c;
                font-size: 12px;
                padding: 4px 5px 4px 5px;
                width: 100%;
                border-radius: 5px;
                border: 3px solid #f3941c;
                text-align: left !important;
              "
              type="button"
              id="dropdownMenuButton"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <img src="assets/img/escudo.png" width="28px" height="28px" />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Calificaciones de los
              Organismos de Control &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </button>
            <div
              class="dropdown-menu"
              aria-labelledby="dropdownMenuButton"
              style="padding: 20px"
            >
              <a
                class="dropdown-item"
                href="assets\pdf\certificado.pdf"
                target="_blank"
                style="border-bottom: 1px solid #dddddd"
              >
                Calificación de la Superintendencia de Compañías, Valores y
                Seguros.
              </a>
              <br /><br />
              <a
                class="dropdown-item"
                href="assets\pdf\Superintendencia.pdf"
                target="_blank"
                style="border-bottom: 1px solid #dddddd"
              >
                Calificación de la Superintendencia de Bancos del Ecuador.
              </a>
            </div>
          </div>
        </div>
        <div class="col-md-5 col-sm-7 col-md-offset-2">
          <!-- Accordion -->
          <div class="accordion">
            <div
              class="panel-group"
              id="accordion"
              role="tablist"
              aria-multiselectable="true"
            >
              <div class="panel panel-default">
                <div class="panel-heading" role="tab" id="headingOne">
                  <h4 class="panel-title">
                    <a
                      class="panel-title-child"
                      role="button"
                      data-toggle="collapse"
                      data-parent="#accordion"
                      href="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      Misión
                    </a>
                  </h4>
                </div>
                <div
                  id="collapseOne"
                  class="panel-collapse collapse"
                  role="tabpanel"
                  aria-labelledby="headingOne"
                >
                  <div class="panel-body" style="text-align: justify">
                    Nuestra Misión es brindar servicios de consultoría
                    corporativa financiera, tributaria, laboral, societaria, de
                    negocios y servicios de auditoria, de calidad, acorde a lo
                    que establecen los estándares y normas aplicables, buscando
                    satisfacer las expectativas y necesidades de nuestros
                    clientes, a través de un conocimiento profundo del negocio,
                    generando Valor Agregado a nuestros clientes y contribuyendo
                    a que los usuarios de la información tales como personas y
                    empresas cuenten y encuentren soluciones viables, fáciles y
                    prácticas, manteniendo principios éticos.
                  </div>
                </div>
              </div>
              <div class="panel panel-default">
                <div class="panel-heading" role="tab" id="headingTwo">
                  <h4 class="panel-title">
                    <a
                      class="collapsed panel-title-child"
                      role="button"
                      data-toggle="collapse"
                      data-parent="#accordion"
                      href="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      Visión
                    </a>
                  </h4>
                </div>
                <div
                  id="collapseTwo"
                  class="panel-collapse collapse"
                  role="tabpanel"
                  aria-labelledby="headingTwo"
                >
                  <div class="panel-body" style="text-align: justify">
                    Ser una Firma reconocida dentro y fuera del país, y la mejor
                    opción para las empresas y personas, en la prestación de
                    servicios de consultoría corporativa financiera, tributaria,
                    laboral, societaria, de negocios y servicios de auditoria,
                    con el objetivo y compromiso de ofrecer a nuestros clientes
                    las mejores prácticas profesionales que le permitan tomar
                    decisiones viables, anteponiendo la ética profesional que
                    nos ha caracterizado y ofreciendo al mismo tiempo las
                    mejores condiciones de trabajo en el mercado para los
                    ejecutivos de nuestra Firma.
                  </div>
                </div>
              </div>
              <div class="panel panel-default">
                <div class="panel-heading" role="tab" id="headingThree">
                  <h4 class="panel-title">
                    <a
                      class="collapsed panel-title-child"
                      role="button"
                      data-toggle="collapse"
                      data-parent="#accordion"
                      href="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      Valores
                    </a>
                  </h4>
                </div>
                <div
                  id="collapseThree"
                  class="panel-collapse collapse"
                  role="tabpanel"
                  aria-labelledby="headingThree"
                >
                  <div class="panel-body">
                    <ul>
                      <li style="color: #fff; font-size: 17px">Integridad</li>
                      <li style="color: #fff; font-size: 17px">Compromiso</li>
                      <li style="color: #fff; font-size: 17px">
                        Responsabilidad
                      </li>
                      <li style="color: #fff; font-size: 17px">Experiencia</li>
                      <li style="color: #fff; font-size: 17px">Capacidad</li>
                      <li style="color: #fff; font-size: 17px">Conocimiento</li>
                      <li style="color: #fff; font-size: 17px">
                        Trabajo en Equipo
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- EQUIPO DE TRABAJO -->
<div
  id="equipo"
  style="
    background-image: url('assets/img/fondos/iniciofondo.png');
    width: 100% !important;
    background-repeat: no-repeat;
    background-size: cover;
  "
>
  <!--STAFF INDIVIDUAL-->
  <div class="content-lg container">
    <div class="row margin-b-40">
      <div class="col-sm-6">
        <h2>Staff Ejecutivo</h2>
      </div>
    </div>

    <div class="row text-center">
      <div class="col-sm-6 sm-margin-b-50">
        <div class="margin-b-20">
          <img
            class=""
            src="assets/img/equipo/gv.jpg"
            style="border-radius: 25px; margin-left: auto; margin-right: auto"
            width="250"
            height="350"
          />
        </div>
        <h4>
          <a href="#">MGTR. CPA GABRIEL VÁSQUEZ</a><br />
          <span class="text-uppercase" style="color: #1d1d1d">
            <u> Socio Líder de Auditoría y Gerente General </u>
          </span>
        </h4>
        <p style="text-align: justify; color: #353535">
          El Ingeniero CPA Gabriel Vásquez realizó sus estudios en la
          Universidad Central del Ecuador en donde obtuvo el título de ingeniero
          en Contabilidad y Auditoría y CPA Contador Público Autorizado, con
          licencia profesional emitido por el Colegio de Contadores Públicos de
          Pichincha (#17-4554).
        </p>
        <a
          class="link"
          data-toggle="collapse"
          href="#detalle3"
          role="button"
          aria-expanded="false"
          aria-controls="collapseExample"
          >Más Detalles</a
        >

        <div class="collapse" id="detalle3">
          <div
            class="card card-body"
            style="
              border: 2px solid #ddd;
              padding: 20px;
              border-radius: 10px;
              text-align: justify;
            "
          >
            El señor Vásquez trabajó durante 12 años para la Firma de Auditoría
            Externa Acevedo y Asociados Cía. Ltda., donde ocupó el cargo de
            Gerente Senior del Departamento de Auditoria.
            <br /><br />Dentro de su experiencia profesional ha estado a cargo
            de la Dirección, Supervisión y Control de Calidad de los trabajos
            ejecutados de auditorías externas en empresas del sector público y
            privado y Fondos Complementarios Previsionales Cerrados controlados
            por la Superintendencia de Bancos del Ecuador y el BIESS, así como
            auditorias especiales bajo procedimientos convenidos en importantes
            empresas locales e internacionales que operan en el país. Ha
            manejado clientes del área comercial, industrial, agroindustrial,
            petrolera, minera, de servicios, fondos complementarios
            previsionales cerrados, fundaciones, etc. <br /><br />Ha participado
            en cursos de entrenamiento sobre Normas Internacionales de
            Auditoría, Normas de la Superintendencia de Bancos del Ecuador,
            Normas de Información Financiera (NIIF completas) y NIIF para PYMES,
            actualizaciones en Tributación Nacional y Fiscalidad Internacional
            impartidos por entidades especializadas en el campo. <br /><br />Ha
            impartido cursos de entrenamiento para personal de empresas privadas
            en procesos de cierre de estados financieros y de cierre fiscal y ha
            impartido conferencias para personal de apoyo (a supervisores,
            senior, asistentes de auditoria) en procesos de auditoría externa de
            empresas del sector público y sector privado, planificación,
            ejecución y elaboración de informes y tributación local e
            internacional. <br /><br />En adición el Ing. CPA Gabriel Vásquez,
            actualmente es el representante legal de la empresa CONSULTORÍA Y
            AUDITORÍA VÁSQUEZ & ASOCIADOS CIA. LTDA., y es el Socio Líder a
            cargo del área de auditoría y consultoría empresarial y es Magíster
            en Contabilidad y Auditoría con Mención en Riesgos Operativos y
            Financieros, de la Pontificia Universidad Católica del Ecuador.
          </div>
        </div>
      </div>

      <div class="col-sm-6 sm-margin-b-50">
        <div class="margin-b-20">
          <img
            class=""
            src="assets/img/equipo/mg.jpg"
            style="border-radius: 25px; margin-left: auto; margin-right: auto"
            width="250"
            height="350"
          />
        </div>
        <h4>
          <a href="#">ING. CPA MARISELA GÓMEZ</a><br />
          <span class="text-uppercase" style="color: #1d1d1d">
            <u> Socia de Contabilidad y Consultoría</u>
          </span>
        </h4>
        <p style="text-align: justify; color: #353535">
          Marisela Gómez realizó sus estudios en la Universidad Internacional
          del Ecuador (UIDE) en donde obtuvo el título de ingeniera en
          Contabilidad y Auditoría y CPA Contador Público Autorizado, con
          licencia profesional emitida por el Colegio de Contadores Públicos de
          Pichincha (#17-6311).
        </p>
        <a
          class="link"
          data-toggle="collapse"
          href="#detalle1"
          role="button"
          aria-expanded="false"
          aria-controls="collapseExample"
          >Más Detalles</a
        >

        <div class="collapse" id="detalle1">
          <div
            class="card card-body"
            style="
              border: 2px solid #ddd;
              padding: 20px;
              border-radius: 10px;
              text-align: justify;
            "
          >
            La señora Gómez trabajó para la Firma de Auditoría Externa Acevedo y
            Asociados Cía. Ltda., donde laboró durante 6 años, y ocupó el cargo
            de Senior del Departamento de Contabilidad.
            <br /><br />Dentro de su experiencia profesional ha estado a cargo
            de la Supervisión de los procesos contables y financieros de
            importantes empresas locales e internacionales que operan en el
            país. Ha manejado clientes del área comercial, industrial,
            agroindustrial, de servicios etc. <br /><br />Ha participado en
            cursos de entrenamiento sobre Normas de Información Financiera (NIIF
            completas) y NIIF para PYMES, actualizaciones en Tributación
            Nacional y Fiscalidad Internacional impartidos por entidades
            especializadas en el campo. <br /><br />Actualmente la Ing. Gómez se
            desempeña como Socia de Contabilidad de la empresa Consultoría y
            Auditoría Vásquez & Asociados Cía. Ltda., y es la Líder Responsable
            de la línea de Consultoría Contable, Financiera y Tributaria de la
            Firma y se encuentra al frente de las áreas contables de importantes
            empresas del país.
          </div>
        </div>
      </div>
    </div>
    <br /><br /><br />
    <!--3 nuevas personas incluidas-->
    <div class="row text-center">
      <div class="col-sm-4 sm-margin-b-50">
        <div class="margin-b-20">
          <img
            class=""
            src="assets/img/equipo/pm.jpg"
            style="border-radius: 25px; margin-left: auto; margin-right: auto"
            width="250"
            height="350"
          />
        </div>
        <h4>
          <a href="#">ING. CPA PAOLA MARTÍNEZ</a><br />
          <span class="text-uppercase" style="color: #1d1d1d">
            <u>Senior de Auditoría</u>
          </span>
        </h4>
        <p style="text-align: justify; color: #353535">
          La Ingeniera Paola Martínez realizó sus estudios en la Universidad
          Central del Ecuador en donde obtuvo el título de ingeniera en
          Contabilidad y Auditoría y CPA Contador Público Autorizado.
        </p>
        <a
          class="link"
          data-toggle="collapse"
          href="#detalle5"
          role="button"
          aria-expanded="false"
          aria-controls="collapseExample"
          >Más Detalles</a
        >

        <div class="collapse" id="detalle5">
          <div
            class="card card-body"
            style="
              border: 2px solid #ddd;
              padding: 20px;
              border-radius: 10px;
              text-align: justify;
            "
          >
            Paola Martínez trabajó para la Firma de Auditoría Externa Alirio
            Morales & Asociados Consultores Cía. Ltda., donde laboró durante 3
            años, y ocupó el cargo de Asistente del área de Auditoría.
            <br /><br />Dentro de su experiencia profesional ha sido personal de
            apoyo en la ejecución de los procesos de auditorías externas, en
            importantes empresas locales que operan en el país del sector
            público y privado y en Fondos Complementarios Previsionales
            Cerrados. Ha manejado clientes del área comercial, industrial,
            agroindustrial, de servicios y fondos complementarios etc.
            Actualmente se desempeña como Senior de Auditoría en la Firma
            Consultoría y Auditoría Vásquez & Asociados Cía. Ltda.
          </div>
        </div>
      </div>

      <div class="col-sm-4 sm-margin-b-50">
        <div class="margin-b-20">
          <img
            class=""
            src="assets/img/equipo/jb.jpg"
            style="border-radius: 25px; margin-left: auto; margin-right: auto"
            width="250"
            height="350"
          />
        </div>
        <h4>
          <a href="#">LCDO. JORGE BRAVO</a><br />
          <span class="text-uppercase" style="color: #1d1d1d">
            <u>Senior de Auditoría</u>
          </span>
        </h4>
        <p style="text-align: justify; color: #353535">
          Jorge Bravo realizó sus estudios en la Universidad Politécnica
          Salesiana en donde obtuvo el título de Licenciado en Contabilidad y
          Auditoría.
        </p>
        <a
          class="link"
          data-toggle="collapse"
          href="#detalle6"
          role="button"
          aria-expanded="false"
          aria-controls="collapseExample"
          >Más Detalles</a
        >

        <div class="collapse" id="detalle6">
          <div
            class="card card-body"
            style="
              border: 2px solid #ddd;
              padding: 20px;
              border-radius: 10px;
              text-align: justify;
            "
          >
            El licenciado Bravo trabajó para la Firma de Auditoría Externa
            Acevedo y Asociados Cía. Ltda., donde laboró durante 4 años, y ocupó
            el cargo de Encargado de auditoría. Dentro de su experiencia
            profesional ha sido personal de apoyo en la ejecución de los
            procesos de auditoría de importantes empresas del país del sector
            público y privado. Ha manejado clientes del área comercial,
            industrial, agroindustrial, de servicios y fondos complementarios,
            etc. Actualmente se desempeña como Senior de Auditoría en la Firma
            Consultoría y Auditoría Vásquez & Asociados Cía. Ltda.
          </div>
        </div>
      </div>

      <div class="col-sm-4 sm-margin-b-50">
        <div class="margin-b-20">
          <img
            class=""
            src="assets/img/equipo/vm.jpg"
            style="border-radius: 25px; margin-left: auto; margin-right: auto"
            width="250"
            height="350"
          />
        </div>
        <h4>
          <a href="#">ING. VERÓNICA MANITIO</a><br />
          <span class="text-uppercase" style="color: #1d1d1d">
            <u> Senior de Contabilidad</u>
          </span>
        </h4>
        <p style="text-align: justify; color: #353535">
          Verónica Manitio realizó sus estudios en la Universidad Politécnica
          Salesiana en donde obtuvo el título de Ingeniera en Contabilidad y
          Auditoría.
        </p>
        <a
          class="link"
          data-toggle="collapse"
          href="#detalle7"
          role="button"
          aria-expanded="false"
          aria-controls="collapseExample"
          >Más Detalles</a
        >

        <div class="collapse" id="detalle7">
          <div
            class="card card-body"
            style="
              border: 2px solid #ddd;
              padding: 20px;
              border-radius: 10px;
              text-align: justify;
            "
          >
            La señora Manitio dentro de su experiencia profesional ha sido
            personal de apoyo en la ejecución de los procesos contables y de
            auditoría de importantes empresas del país del sector privado. Ha
            manejado clientes del área comercial, industrial, agroindustrial, de
            servicios y fondos complementarios, etc. Actualmente se desempeña
            como Senior de Contabilidad en la Firma Consultoría y Auditoría
            Vásquez & Asociados Cía. Ltda.
          </div>
        </div>
      </div>
    </div>
    <!--Fin 3 colaboradore -->
  </div>
  <!--CARUSEL-->
  <div
    id="carrusel-Equipo"
    class="carousel slide container"
    data-ride="carousel"
    data-interval="4000"
  >
    <ol class="carousel-indicators">
      <li data-target="#carrusel-Equipo" data-slide-to="0" class="active"></li>
      <li data-target="#carrusel-Equipo" data-slide-to="1"></li>
      <li data-target="#carrusel-Equipo" data-slide-to="2"></li>
      <li data-target="#carrusel-Equipo" data-slide-to="3"></li>
      <li data-target="#carrusel-Equipo" data-slide-to="4"></li>
      <li data-target="#carrusel-Equipo" data-slide-to="5"></li>
      <li data-target="#carrusel-Equipo" data-slide-to="6"></li>
      <li data-target="#carrusel-Equipo" data-slide-to="7"></li>
      <li data-target="#carrusel-Equipo" data-slide-to="8"></li>
      <li data-target="#carrusel-Equipo" data-slide-to="9"></li>
      <li data-target="#carrusel-Equipo" data-slide-to="10"></li>
      <li data-target="#carrusel-Equipo" data-slide-to="11"></li>
      <li data-target="#carrusel-Equipo" data-slide-to="12"></li>
      <li data-target="#carrusel-Equipo" data-slide-to="13"></li>
      <li data-target="#carrusel-Equipo" data-slide-to="14"></li>
      <li data-target="#carrusel-Equipo" data-slide-to="15"></li>
    </ol>
    <!--imagenes carrusel -->
    <div class="carousel-inner" role="listbox">
      <div class="item active">
        <img
          class="img-responsive"
          src="assets/img/carruselEquipo/1.jpg"
          alt="Slider Image"
        />
      </div>
      <div class="item">
        <img
          class="img-responsive"
          src="assets/img/carruselEquipo/2.jpg"
          alt="Slider Image"
        />
      </div>
      <div class="item">
        <img
          class="img-responsive"
          src="assets/img/carruselEquipo/3.jpg"
          alt="Slider Image"
        />
      </div>
      <div class="item">
        <img
          class="img-responsive"
          src="assets/img/carruselEquipo/4.jpg"
          alt="Slider Image"
        />
      </div>
      <div class="item">
        <img
          class="img-responsive"
          src="assets/img/carruselEquipo/5.jpg"
          alt="Slider Image"
        />
      </div>
      <div class="item">
        <img
          class="img-responsive"
          src="assets/img/carruselEquipo/6.jpg"
          alt="Slider Image"
        />
      </div>
      <div class="item">
        <img
          class="img-responsive"
          src="assets/img/carruselEquipo/7.jpg"
          alt="Slider Image"
        />
      </div>
      <div class="item">
        <img
          class="img-responsive"
          src="assets/img/carruselEquipo/8.jpg"
          alt="Slider Image"
        />
      </div>
      <div class="item">
        <img
          class="img-responsive"
          src="assets/img/carruselEquipo/9.jpg"
          alt="Slider Image"
        />
      </div>
      <div class="item">
        <img
          class="img-responsive"
          src="assets/img/carruselEquipo/10.jpg"
          alt="Slider Image"
        />
      </div>
      <div class="item">
        <img
          class="img-responsive"
          src="assets/img/carruselEquipo/11.jpg"
          alt="Slider Image"
        />
      </div>
      <div class="item">
        <img
          class="img-responsive"
          src="assets/img/carruselEquipo/12.jpg"
          alt="Slider Image"
        />
      </div>
      <div class="item">
        <img
          class="img-responsive"
          src="assets/img/carruselEquipo/13.jpg"
          alt="Slider Image"
        />
      </div>
      <div class="item">
        <img
          class="img-responsive"
          src="assets/img/carruselEquipo/14.jpg"
          alt="Slider Image"
        />
      </div>
      <div class="item">
        <img
          class="img-responsive"
          src="assets/img/carruselEquipo/15.jpg"
          alt="Slider Image"
        />
      </div>
    </div>
    <!-- Controls -->
    <a
      class="left carousel-control"
      href="#carrusel-Equipo"
      role="button"
      data-slide="prev"
    >
      <span class="glyphicon glyphicon-chevron-left" aria-hidden="true"></span>
      <span class="sr-only">Previous</span>
    </a>
    <a
      class="right carousel-control"
      href="#carrusel-Equipo"
      role="button"
      data-slide="next"
    >
      <span class="glyphicon glyphicon-chevron-right" aria-hidden="true"></span>
      <span class="sr-only">Next</span>
    </a>
  </div>
  <!--FOTOS DE EQUIPO
  <div class="content-lg container">
    <div class="row">
      <div class="col-sm-4 sm-margin-b-50">
        <img
          src="assets/img/equipo/336.png"
          style="
            width: 100%;
            height: auto;
            margin-left: auto;
            margin-right: auto;
          "
        />
      </div>
      <div class="col-sm-4 sm-margin-b-50">
        <img
          src="assets/img/equipo/337.png"
          style="
            width: 100%;
            height: auto;
            margin-left: auto;
            margin-right: auto;
          "
        />
      </div>
      <div class="col-sm-4 sm-margin-b-50">
        <img
          src="assets/img/equipo/338.png"
          style="
            width: 100%;
            height: auto;
            margin-left: auto;
            margin-right: auto;
          "
        />
      </div>
    </div>
  </div>
  -->
</div>

<!-- OBJETIVOS -->
<div id="objetives">
  <div class="bg-color-sky-light" data-auto-height="true">
    <div class="content-lg container">
      <div class="row margin-b-40">
        <div class="col-sm-6">
          <h2>Objetivo</h2>
        </div>
      </div>
      <div class="row margin-b-40">
        <div class="col-sm-6">
          <img
            src="assets/img/objetivo.jpg"
            style="width: 100%; height: 215px; margin: 5px; border-radius: 5px"
          />
        </div>
        <div class="col-sm-6">
          <p
            class="tsub"
            style="
              border: 5px double #8ab6cc;
              margin: 5px;
              padding: 15px;
              border-radius: 5px;
              color: #353535;
              font-size: 18px;
              text-align: justify;
            "
          >
            <b>
              El principal objetivo de la Firma es asegurar un enfoque de
              auditoría externa financiera consistente, personalizado,
              proactivo, que agregue valor a la compañía mediante la aplicación
              de todos los estándares profesionales relevantes, para mantener
              servicios de la más alta calidad, en base a requerimientos,
              prácticas y procedimientos tanto locales como internacionales.
            </b>
          </p>
        </div>
      </div>
      <div class="row row-space-1 margin-b-2">
        <div class="col-sm-4 sm-margin-b-2">
          <div
            class="service"
            data-height="height"
            style="background-color: #049bcb"
          >
            <div class="service-element">
              <i class="service-icon color-white icon-paper-clip"></i>
            </div>
            <div class="service-info">
              <h3 class="color-white">Metodología</h3>
              <p class="color-white margin-b-5">
                Nuestra metodología de auditoría externa financiera tiene como
                finalidad el examinar los estados financieros y a través de
                ellos las transacciones contables realizadas por la entidad, con
                los requisitos exigidos por las Normas Internacionales de
                Auditoría – NIA.
              </p>
            </div>
          </div>
        </div>
        <div class="col-sm-4 sm-margin-b-2">
          <div class="service bg-color-base" data-height="height">
            <div class="service-element">
              <i class="service-icon color-white icon-info"></i>
            </div>
            <div class="service-info">
              <h3 class="color-white">Recomendaciones</h3>
              <p class="color-white margin-b-5">
                Presentamos recomendaciones para mejorar el desempeño en áreas
                tales como gerencia, control interno, operaciones, contabilidad
                y sistemas de reportes financieros.
              </p>
            </div>
          </div>
        </div>
        <div class="col-sm-4">
          <div
            class="service"
            data-height="height"
            style="background-color: #049bcb"
          >
            <div class="service-element">
              <i class="service-icon color-white icon-notebook"></i>
            </div>
            <div class="service-info">
              <h3 class="color-white">Opinión</h3>
              <p class="margin-b-5 color-white">
                Según los requerimientos locales, también expresamos una opinión
                sobre el cumplimiento de obligaciones tributarias. En los casos
                requeridos por las entidades reguladoras, también emitimos
                informes adicionales específicos.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="row row-space-1">
        <div class="col-sm-4">
          <div
            class="service"
            data-height="height"
            style="background-color: #b3bccb"
          >
            <div class="service-element">
              <i class="service-icon color-white icon-link"></i>
            </div>
            <div class="service-info">
              <h3 class="color-white">Implementación</h3>
              <p class="margin-b-5 color-white">
                Brindamos la asesoría en la implementación, seguimiento y
                monitoreo de en la adopción de las “Normas Internacionales de
                Información Financiera - NIIF” y NIIF para Pymes en los estados
                financieros de las empresas.
              </p>
            </div>
          </div>
        </div>
        <div class="col-sm-4 sm-margin-b-2">
          <div
            class="service"
            data-height="height"
            style="background-color: #547c94"
          >
            <div class="service-element">
              <i class="service-icon color-white icon-speedometer"></i>
            </div>
            <div class="service-info">
              <h3 class="color-white">Análisis y Reconstrucción</h3>
              <p class="margin-b-5 color-white">
                Análisis y Reconstrucción de Estados Financieros en caso de no
                haber existido una contabilidad armada, planteamiento de ajustes
                para una razonable presentación de los estados financieros.
              </p>
            </div>
          </div>
        </div>
        <div class="col-sm-4 sm-margin-b-2">
          <div
            class="service"
            data-height="height"
            style="background-color: #b3bccb"
          >
            <div class="service-element">
              <i class="service-icon color-white icon-calendar"></i>
            </div>
            <div class="service-info">
              <h3 class="color-white">Seguimiento</h3>
              <p class="margin-b-5 color-white">
                Realizar el seguimiento continuo en la aplicación de las
                políticas contables de acuerdo a las Normas (NIIF). Presentando
                a la Administración Superior de la compañía, un detalle
                comparativo de todos los principios de contabilidad de acuerdo a
                Normas NEC.
              </p>
            </div>
          </div>
        </div>
      </div>

      <br />

      <div class="row">
        <div class="col-12 text-center">
          <button class="btn-contactanos" (click)="navegar(6)">
            Contáctate con Nosotros <i class="fa fa-envelope ml-2"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<br />
<app-component-footer></app-component-footer>
