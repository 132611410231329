<app-component-menu></app-component-menu>
<img class="img-responsive" src="assets/img/portafolio.png" style="height: 300px; width: 100%;">
<br><br>
<div id="portafolio">
    <div class="content-lg container">
    
        <div class="row">
          <div class="col-sm-7 sm-margin-b-50">
              <h2 style="padding-top: 35px;">Boletines</h2>
        
        <!-- Latest Products -->
        <div class="col-sm-7 sm-margin-b-50">
            <div class="margin-b-20">
                <img class="img-responsive" src="assets/img/reporte.jpg">
            </div>
            <h4><a href="#">2021</a> <span class="text-uppercase margin-l-20"></span></h4>
            <p>Los siguientes boletines corresponden al año 2021</p>
            <a class="link" data-toggle="collapse" href="#boletines2021" role="button" aria-expanded="false"
          aria-controls="collapseExample">Lista de Boletines 2021</a>

        <div class="collapse" id="boletines2021">
          <div class="card card-body" style="border: 2px solid #ddd; padding: 20px; border-radius: 10px;">
            <ul style="list-style-type: none;">
                <li><a href="assets\pdf\certificado.pdf" target="_blank">Enero</a></li>
                <li>Febrero</li>
                <li>Marzo</li>
                <li>Abril</li>
                <li>Mayo</li>
                <li>Junio</li>
                <li>Julio</li>
                <li>Agosto</li>
                <li>Septiembre</li>
                <li>Octubre</li>
                <li>Noviembre</li>
                <li>Diciembre</li>
            </ul>


          </div>
        </div>
        <br><br>
        </div>
   
        <!-- End Latest Products -->
          <!-- Latest Products -->
        <div class="col-sm-7 sm-margin-b-50">
                    <div class="margin-b-20">
                        <img class="img-responsive" src="assets/img/reporte.jpg">
                    </div>
                    <h4><a href="#">2020</a> <span class="text-uppercase margin-l-20"></span></h4>
                    <p>Los siguientes boletines corresponden al año 2020</p>
                    <a class="link" data-toggle="collapse" href="#boletines2020" role="button" aria-expanded="false"
                  aria-controls="collapseExample">Lista de Boletines 2020</a>
        
                <div class="collapse" id="boletines2020">
                  <div class="card card-body" style="border: 2px solid #ddd; padding: 20px; border-radius: 10px;">
                    <ul style="list-style-type: none;">
                        <li><a href="assets\pdf\certificado.pdf" target="_blank">Enero</a></li>
                        <li>Febrero</li>
                        <li>Marzo</li>
                        <li>Abril</li>
                        <li>Mayo</li>
                        <li>Junio</li>
                        <li>Julio</li>
                        <li>Agosto</li>
                        <li>Septiembre</li>
                        <li>Octubre</li>
                        <li>Noviembre</li>
                        <li>Diciembre</li>
                    </ul>
        
        
                  </div>
                </div>
        </div>
        <!-- End Latest Products -->
           
          </div>
    
          <div class="col-sm-4 sm-margin-b-50" >
              <h2 style="padding-top: 35px;">Enlaces de Interés</h2>
       
              <div style="background-image: url('assets/img/fondos/iniciofondo3.png'); width: auto !important;  
              background-repeat: no-repeat; border-radius: 10px; 
              background-size: cover;" >
                <ul style="line-height: 30px !important;">
                  <li><a href="">CONSULTORÍA EN EL ÁREA TRIBUTARIA</a></li>
                  <li><a href="">CONSULTORÍA FINANCIERA</a></li>
                  <li><a href="">CONSULTORÍA Y AUDITORÍA SOCIETARIA</a></li>
                  <li><a href="">ASESORÍA LABORAL</a></li>
                  <li><a href="">AUDITORÍAS EXTERNAS Y REVISIONES FINANCIERAS</a></li>
                  <li><a href="">CONSULTORÍA EN EL ÁREA TRIBUTARIA</a></li>
                  <li><a href="">CONSULTORÍA FINANCIERA</a></li>
                  <li><a href="">CONSULTORÍA Y AUDITORÍA SOCIETARIA</a></li>
                  <li><a href="">ASESORÍA LABORAL</a></li>
                  <li><a href="">AUDITORÍAS EXTERNAS Y REVISIONES FINANCIERAS</a></li>
                  <li><a href="">CONSULTORÍA EN EL ÁREA TRIBUTARIA</a></li>
                  <li><a href="">CONSULTORÍA FINANCIERA</a></li>
                  <li><a href="">CONSULTORÍA Y AUDITORÍA SOCIETARIA</a></li>
                  <li><a href="">ASESORÍA LABORAL</a></li>
                  <li><a href="">AUDITORÍAS EXTERNAS Y REVISIONES FINANCIERAS</a></li>
                  <li><a href="">CONSULTORÍA EN EL ÁREA TRIBUTARIA</a></li>
                  <li><a href="">CONSULTORÍA FINANCIERA</a></li>
                  <li><a href="">CONSULTORÍA Y AUDITORÍA SOCIETARIA</a></li>
                  <li><a href="">ASESORÍA LABORAL</a></li>
                  <li><a href="">AUDITORÍAS EXTERNAS Y REVISIONES FINANCIERAS</a></li>
              </ul>
              </div>
            

          </div>
        </div>
      </div>
  </div>
<app-component-footer></app-component-footer>